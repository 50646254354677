<template>
  <div class="arrondi">
    <div class="head">
      <ul>
        <li :class="type == 1 ? 'active' : ''" @click="typeFun(1)">
          周末卷卷堂
        </li>
        <li :class="type == 2 ? 'active' : ''" @click="typeFun(2)">会员优秀作业</li>
      </ul>
    </div>
    <div class="content">
      <!-- <div class="img"><img :src="info.thumb"  @click="playFun"/></div> -->
      <div class="video_info_play">
        <div class="video_info_play_start" @click="playFun" v-if="!isPlay">
          <img class="video_info_play_start_thumb" :src="info.thumb" alt="" />
          <img class="playClick" src="../assets/images/play.png" alt="" />
        </div>
        <div v-show="isPlay" id="player"></div>
      </div>

      <div class="right">
        <h2>【周末卷卷堂】{{ info.title }}</h2>
        <span class="antistop">关键词：<a v-for="(item,index) of antistop" :key="index" @click="antFun(item)">{{item}}</a></span>
        <span>{{ info.start_time }}—{{ info.end_time }}</span>
        <div class="desc">
          {{ info.introduce }}
        </div>
        <div class="btn">
          <a class="chk" @click="upload">上传活动作品</a>
          <a class="up" @click="downFun(info.video_id)">下载课程源文件</a>
        </div>
      </div>
    </div>
    <div class="works_head">
      <a :class="active == 1 ? 'active' : ''" @click="activeFun(1)">活动作品</a>
      <!-- <a :class="active==2?'active':''" @click="activeFun(2)">获奖作品</a> -->
    </div>

    <div class="works_content">
      <div class="daily_ul_wrap" v-show="list_total > 0">
        <div
          class="works_content_one video_list video_lists clearfix"
          v-if="active == 1"
        >
          <div class="daily_ul" v-for="(row, i) of list" :key="i">
            <!-- :style="{ animationDelay: 0.1 * index + 's' }" -->
            <div class="video_li" v-for="(item, index) of row" :key="index">
              <div class="video_li_box">
                <img v-lazy="item.thumb" @click="showImg(item.thumb)" alt="" />
              </div>
              <div class="clearfix pad10">
                <div
                  class="fl dailyWord_li_l"
                  @click.stop="goodsTaskFun(item.user.id)"
                >
                  <img class="fl" :src="item.user.thumb" alt="" />
                  <span>{{ item.user.username }}</span>
                </div>
                <div class="fr dailyWord_li_r">
                  <div
                    :class="item.is_like == 1 ? 'zan zanActive' : 'zan'"
                    @click="good(item.id)"
                  >
                    <img
                      src="../assets/images/zan_new.svg"
                      v-if="item.is_like == 1"
                    />
                    <img src="../assets/images/zan_new2.svg" v-else />
                    <span>{{ item.goods }}</span>
                  </div>
                </div>
              </div>
              <div :class="'goods goods' + item.activity_ranking"></div>
            </div>
          </div>
        </div>
        <page
          v-if="list_total > limit"
          :page="page"
          :limit="limit"
          :total="list_total"
          ref="page"
        ></page>
      </div>
      <el-empty description="暂无作品" v-show="list_total == 0"></el-empty>
      <div
        class="works_content_one video_list video_lists clearfix"
        v-if="active == 2"
      >
        <div class="daily_ul" v-for="(row, i) of list" :key="i">
          <!-- :style="{ animationDelay: 0.1 * index + 's' }" -->
          <div class="video_li" v-for="(item, index) of row" :key="index">
            <div class="video_li_box">
              <img v-lazy="item.thumb" alt="" />
            </div>
            <div class="clearfix">
              <div
                class="fl dailyWord_li_l"
                @click.stop="$parent.getUser(item.user.id)"
              >
                <img class="fl" :src="item.user.thumb" alt="" />
                <span>{{ item.user.name }}</span>
              </div>
              <div class="fr dailyWord_li_r">
                <div
                  :class="item.is_like == 1 ? 'zan zanActive' : 'zan'"
                  @click="good(item.id)"
                >
                  <img
                    src="../assets/images/zan_new.svg"
                    v-if="item.is_like == 1"
                  />
                  <img src="../assets/images/zan_new2.svg" v-else />
                  <span>{{ item.goods }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="background" v-show="editShow">
      <div class="edit_task_pop">
        <div class="edit_task_pop_head">
          <h2>上传作品</h2>
          <a><i class="el-icon-close" @click="editTaskClose"></i></a>
        </div>
        <div class="edit_task_pop_content">
          <div class="edit_task_pop_content_upload">
            <!-- <div class="task_pop_thumb">
              <img :src="editImage" />
            </div> -->
            <div class="task_pop_upload dailyWord_upload_img">
              <!-- <button>重新上传</button> -->
              <el-upload
                class="upload-demo"
                :show-file-list="false"
                drag
                :headers="headers"
                :action="$api.url + '/api/' + $face.defaultUpload"
                :on-success="handleAvatarSuccessFun"
                :before-upload="beforeAvatarUpload"
                accept=".png, .jpg, .jpeg"
              >
                <img v-if="editImage" :src="editImage" class="up_img" />
                <template v-else>
                  <i class="upload_icon"></i>
                  <div class="el-upload__text">
                    点击 / 拖拽图片文件到这里上传
                  </div>
                  <div class="el-upload__tip">
                    单张图片大小5M以内 ( 支持jpg、png格式 )
                  </div>
                </template>
              </el-upload>
            </div>
          </div>
          <div class="task_pop_desc">
            <textarea
              v-model="editCon"
              placeholder="请上传你的作业描述"
            ></textarea>
          </div>
          <div class="task_pop_btn">
            <button @click="editTaskFun">提交作品</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="imgDolg"
      v-show="imgPreview.show"
      @click.stop="imgPreview.show = false"
    >
      <i
        class="el-icon-close"
        id="imgDolgClose"
        @click.stop="imgPreview.show = false"
      ></i>
      <img @click.stop="imgPreview.show = true" :src="imgPreview.img" />
    </div>
  </div>
</template>

<script>
const polyvPlayer = window.polyvPlayer;
import page from "../components/page.vue";
export default {
  name: "arrondi",
  components: {
    page,
  },
  data() {
    return {
      id: 0,
      type: 1,
      active: 1,
      editShow: false,
      editCon: "",
      editImage: "",
      headers: {
        token: localStorage.getItem("access_token"),
      },
      page: 1,
      limit: 16,
      info: [],
      list: [],
      list_total: 0,
      imghight: 0,
      playerHook: 0,
      //保利威视视频
      vodPlayerJs: "//player.polyv.net/resp/vod-player-drm/canary/player.js",
      vid: "88083abbf5bcf1356e05d39666be527a_8",
      startTime: 0,
      isPlay: false,
      video_token: null,
      antistop: [], //关键词
      imgPreview: {
        img: "",
        show: false,
      },
    };
  },
  player: null,
  computed: {
    player: function () {
      return this.playerHook || this.$options.player;
    },
  },
  mounted() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    this.id = this.$route.query.id;
    this.listFun(0);
    this.$parent.routerIndex = 8;
    this.$parent.showIndex = 1;
    this.$parent.shadow = false;
    let self = this;
    this.$nextTick(function () {
      document.addEventListener("keydown", function (e) {
        //此处填写你的业务逻辑即可
        if (e.keyCode == 27) {
          self.imgPreview.img = "";
          self.imgPreview.show = false;
        }
      });
    });
  },
  methods: {
    listFun() {
      this.$api.POST(
        this.$face.video_activity_info,
        {
          id: this.id,
          page: this.page,
          limit: this.limit,
        },
        (res) => {
          if (res.code == 200) {
            this.info = res.data.info;
            this.video_token = res.data.video_token;
            let list = res.data.task_list;
            for (let i in list) {
              if (list[i].user.thumb.indexOf("http") == -1) {
                list[i].user.thumb = require("../assets/images/head.png");
              }
            }
            var label = "";
            if (this.info.label.indexOf("，") != -1) {
              label = this.info.label.replace(/，/g, ",");
            } else {
              label = this.info.label;
            }
            this.antistop = label.split(",");
            this.list = this.$utile.listData(list);
            console.log(this.list);
            this.list_total = res.data.task_total;
            this.playerTimeFun(res.data.info.video_id);
          }
        }
      );
    },
    playerTimeFun(id) {
      this.startTime = 0;
      var that = this;
      var params = {
        video_id: id,
      };
      this.$api.POST(this.$face.videoPlayrecord, params, function (res) {
        that.startTime = res.data.list_time;
        if (that.startTime > 0) {
          // that.playFun();
        }
      });
    },
    //点赞
    good(id) {
      if (!this.headers.token) {
        this.$parent.loginShow();
        return;
      }
      var that = this;
      var params = {
        task_id: id,
      };
      this.$api.POST(this.$face.good, params, function (res) {
        if (res.code == 200) {
          for (let i in that.list) {
            for (let i2 of that.list[i]) {
              if (i2.id == id) {
                if (res.msg == "取消成功") {
                  i2.is_like = 0;
                  i2.goods = i2.goods - 1;
                } else {
                  i2.is_like = 1;
                  i2.goods = i2.goods + 1;
                }
              }
            }
          }
        }
      });
    },
    activeFun(active) {
      this.active = active;
      this.getInfo(active - 1);
    },
    typeFun(type) {
      this.$router.push({
        name: "arrondi",
        query: {
          type: type,
        },
      });
    },
    upload() {
      if (this.info.activity_status == 0) {
        this.$utile.prompt("success", "活动未开始");
        return false;
      }
      if (this.info.activity_status == 2) {
        this.$utile.prompt("success", "活动已结束");
        return false;
      }

      this.editShow = true;
    },
    editTaskFun() {
      if (!this.headers.token) {
        this.$parent.loginShow();
        return;
      }

      if (!this.editImage) {
        this.$utile.prompt("success", "请上传作品后提交");
        return false;
      }

      var that = this;
      var params = {
        video_id: this.info.video_id,
        content: this.editCon,
        thumb: this.editImage,
        activity_id: this.info.id,
        is_activity: 1,
        imghight: this.imghight,
      };
      this.$api.POST(this.$face.videoTask, params, function (res) {
        that.$utile.prompt("success", "作品已提交，等待后台审核");
        that.editTaskClose();
      });
    },
    playFun() {
      if (
        localStorage.getItem("access_token") == "" ||
        localStorage.getItem("access_token") == undefined ||
        localStorage.getItem("access_token") == null
      ) {
        this.$parent.loginShow();
        return;
      }
      var that = this;
      var params = {
        video_id: this.info.video_id,
      };
      this.$api.POST(this.$face.videoPlay, params, function (res) {
        that.isPlay = true;
        that.$nextTick(() => {
          that.loadPlayerScript(that.loadPlayer);
        });
      });
    },
    //保利威视播放视频
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },

    loadPlayer() {
      const polyvPlayer = window.polyvPlayer;
      this.$options.player = polyvPlayer({
        wrap: "#player",
        width: "100%",
        height: "100%",
        autoplay: true,
        forceH5: true,
        forceHTML5: true,
        vid: this.info.blw_id,
        playsafe: this.video_token.token,
        ts: this.video_token.ts,
        sign: this.video_token.sign,
        watchStartTime: this.startTime || 0,
        df: 3,
      });
      this.$options.player.on("s2j_onPlayOver", (...params) => {
        this.playOverFun();
      });
      this.$options.player.on("s2j_onVideoPlay", (...params) => {
        this.polling();
      });
      this.$options.player.on("s2j_onVideoPause", (...params) => {
        clearInterval(this.otimer);
      });
      this.$options.player.on("s2j_onPlayOver", (...params) => {
        clearInterval(this.otimer);
      });
    },
    playOverFun() {
      var that = this;
      var params = {
        video_id: this.info.video_id,
      };
      this.$api.POST(this.$face.videoPlayok, params, function (res) {});
    },
    //轮询
    polling() {
      clearInterval(this.otimer);
      this.otimer = setInterval(() => {
        this.keep();
      }, 5000);
    },
    keep() {
      var that = this;
      if (this.$options.player) {
        // if (this.startTime <= this.$options.player.j2s_getCurrentTime()) {
        this.$api.POST(
          this.$face.videoPlaypause,
          {
            video_id: this.info.video_id,
            list_time: this.$options.player.j2s_getCurrentTime(),
            total_time: this.$options.player.j2s_getDuration(),
          },
          function (res) {}
        );
        // }
      }
    },
    // 查看大图
    showImg(e) {
      if (e) {
        this.imgPreview.img = e;
        this.imgPreview.show = true;
      }
    },
    // 下载源文件
    downFun() {
      if (
        localStorage.getItem("access_token") == "" ||
        localStorage.getItem("access_token") == undefined ||
        localStorage.getItem("access_token") == null
      ) {
        this.$parent.loginShow();
        return;
      }

      var that = this;
      var params = {
        id: this.info.video_id,
      };
      this.$api.POST(this.$face.videoDown, params, function (res) {
        const a = document.createElement("a");
        //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
        a.href = res.data.source_file; // 完整的url则直接使用
        // document.body.appendChild(a)
        a.click();
      });
    },
    antFun(e) {
      localStorage.setItem("video_name", e.replace(/(^\s*)|(\s*$)/g, ""));
      let routeUrl = this.$router.resolve({
        name: "video",
      });
      window.open(routeUrl.href, "_blank");
    },
    handleAvatarSuccessFun(res, file) {
      this.editImage = res.initialPreview[0];
      this.imghight = res.initialPreviewConfig[0].high;
    },
    goodsTaskFun(id) {
      let routeData = this.$router.resolve({
        name: "userWork",
        query: {
          userId: id,
          type: 1,
        },
      });
      window.open(routeData.href, "_blank");
    },
    //关闭修改作业
    editTaskClose() {
      this.editImage = "";
      this.editCon = "";
      this.editShow = false;
    },
    cancelJob() {
      this.editImage = "";
    },
    goDetail(id) {
      let routeData = this.$router.resolve({
        name: "videoDetail",
        query: {
          id: id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 5;

      // if (!isJPG) {
      // this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$utile.prompt("error", "上传图片大小不能超过 5MB!");
      }
      return isLt2M;
    },
  },
  destroyed() {
    this.keep();
    clearInterval(this.otimer);
    this.$options.player.destroy();
    // window.removeEventListener('popstate', this.goPageBefore, false);
  },
  activated() {
    this.$nextTick(() => {
      this.$parent.routerIndex = 8;
    });
  },
  watch: {
    "$store.state.userInfo"(newval) {
      this.headers.token = localStorage.getItem("access_token");
    },
  },
};
</script>

<style scoped>
.arrondi {
  border-top: 1px #ccc solid;
}
.head {
  height: 57px;
  background: #fff;
}
.head ul {
  max-width: 1315px;
  margin: auto;
}
.head li {
  float: left;
  height: 55px;
  line-height: 55px;
  font-size: 16px;
  color: #555555;
  cursor: pointer;
}
.head li:first-child {
  margin-right: 75px;
}
.head li.active {
  border-bottom: 2px #555555 solid;
}
.content {
  height: 360px;
  width: 1315px;
  margin: 20px auto;
  display: flex;
}
.content .img {
  width: 520px;
  height: 360px;
  position: relative;
  float: left;
}
.content .img img {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.content .right {
  margin-left: 36px;
  position: relative;
  height: 360px;
}
.content .right h2 {
  font-size: 26px;
  overflow: hidden;
  margin-left: -10px;
  padding-top: 20px;
  white-space:normal;
  word-break:break-all;
  line-height: 30px;
}
.content .right span {
  display: block;
  padding: 10px 0 20px;
  color: #999999;
}
.content .right .antistop{ padding: 13px 0 0;}
.content .right span a{ margin-right: 10px; cursor: pointer;}
.content .right .desc {
  font-size: 14px;
  line-height: 22px;
  color: #656464;
  white-space:normal;
  word-break:break-all;
  white-space: pre-line;
  max-height: 180px;
    overflow: hidden;
}
.btn {
  width: 431px;
  position: absolute;
  bottom: 0;
}
.btn a {
  display: block;
  width: 200px;
  height: 43px;
  border-radius: 12px;
  border: 2px #5857ff solid;
  text-align: center;
  line-height: 43px;
  float: left;
  font-size: 20px;
  cursor: pointer;
}
.btn a.chk {
  background: #5857ff;
  color: #fff;
  margin-right: 23px;
}
.btn a.up {
  color: #5857ff;
}
.works_head {
  width: 1315px;
  margin: auto;
}
.works_head a {
  font-size: 16px;
  margin-right: 48px;
  color: #818181;
}
.works_head a.active {
  color: #4a4a4a;
}

/* 视频教程 */
.video_list {
  position: relative;
  z-index: 9;
  min-height: 200px;
}

.video_lists {
  display: flex;
}

.daily_ul {
  margin: 0 9.33px;
  width: calc(100% / 4);
}

.video_li {
  width: 305px;
  margin: 0 0 24px;
  background: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
}

.video_lis {
}

.video_li_box {
  width: 100%;
  /* height: 198px; */
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px #dcdcdc solid;
}
.video_li_box img {
  transition: 0.5s;
  width: 100%;
}
.video_li_box img:hover{ transform: scale(1.05);}

.video_li_mask {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  z-index: 33;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: default;
}

.video_li_play {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 101;
  margin-left: -37.5px;
  margin-top: -37px;
  width: 74px;
  height: 75px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  opacity: 0;
}

.video_li:hover .video_li_mask {
  opacity: 1;
}

.video_li:hover .video_li_play {
  opacity: 1;
}

.video_li_name {
  /* height: 52px;
    line-height: 49px; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #565656;
  width: 210px;
  overflow: hidden;
  /* 第二步：让文本不会换行， 在同一行继续 */
  white-space: nowrap;
  /* 第三步：用省略号来代表未显示完的文本 */
  text-overflow: ellipsis;
}

.video_li_reading {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #999999;
  margin-top: 10px;
}

.video_li_reading img {
  width: auto;
  height: 20px;
  margin-right: 4px;
}

.video_li_reading span {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #999999;
}

.video_li_reading_k {
}
.dailyWord_li_l {
  line-height: 24px;
  font-size: 14px;
  color: #999999;
  cursor: pointer;
  margin-top: 15px;
}
.dailyWord_li_l img {
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border: solid 1px #f6f6f6;
  box-sizing: border-box;
  border-radius: 36px;
  margin-right: 8px;
}
.dailyWord_li_r {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #999999;
  margin-top: 15px;
}
.daily_li:hover .ranking_li_box img {
  transform: scale(1.08);
}
.zan {
  line-height: 16px;
  font-size: 13px;
  cursor: pointer;
}
.zan img {
  float: left;
  width: 14px;
  height: 14px;
  margin-right: 2px;
}
.works_content {
  width: 1305px;
  margin: 13px auto 20px;
  background: #fff !important;
  padding: 20px 10px;
  border-radius: 10px;
}
.goods {
  position: absolute;
  right: -2px;
  top: -3px;
  width: 86px;
  height: 86px;
  background-size: 86px;
  background-repeat: no-repeat;
}
.goods3 {
  background-image: url("../assets/images/good_one.png");
}
.goods2 {
  background-image: url("../assets/images/good_two.png");
}
.goods1 {
  background-image: url("../assets/images/good_three.png");
}
.edit_task_pop {
  position: fixed;
  width: 820px;
  height: 556px;
  background-color: #eff3f5;
  border-radius: 8px;
  top: 50%;
  left: 50%;
  margin-top: -278px;
  margin-left: -410px;
  z-index: 1002;
  opacity: 1 !important;
}
.edit_task_pop_head {
  height: 60px;
  border-bottom: 1px #e0e0e0 solid;
  line-height: 60px;
  padding: 0 30px;
}
.edit_task_pop_head h2 {
  float: left;
  font-size: 18px;
  color: #5857ff;
  height: 59px;
  border-bottom: 2px #5857ff solid;
}
.edit_task_pop_head a {
  float: right;
  font-size: 28px;
  cursor: pointer;
}
.edit_task_pop_content_upload {
  width: 756px;
  height: 233px;
  background-color: #ffffff;
  border-radius: 5px;
  border: solid 1px #eeeeee;
  margin: 22px auto 12px;
  display: flex;
}
.task_pop_thumb {
  width: 210px;
  height: 210px;
  margin-left: 11px;
  margin-top: 11px;
  border: 1px #eee solid;
  background: url(../assets/images/yulan.svg) no-repeat;
  position: relative;
}
.task_pop_thumb img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
/* .task_pop_upload {
  height: 210px;
  margin-left: 11px;
  margin-top: 11px;
  flex: 1;
  text-align: center;
}
.task_pop_upload .upload-demo {
  margin: 0 auto 0;
  width: 193px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #b5b5b5;
  color: #fff;
  cursor: pointer;
  line-height: 40px;
  font-size: 16px;
} */
.task_pop_upload > div {
  font-size: 12px;
  color: #999999;
  line-height: 20px;
  margin-top: 12px;
}
.task_pop_desc {
  width: 756px;
  height: 137px;
  background-color: #ffffff;
  border-radius: 5px;
  border: solid 1px #eeeeee;
  margin: auto;
}
.task_pop_desc textarea {
  width: 728px;
  height: 109px;
  outline: none;
  border: none;
  padding: 14px;
  resize: none;
}
.task_pop_desc textarea::placeholder {
  color: #999999;
  font-size: 14px;
}
.task_pop_btn {
  margin: 23px auto 0;
  text-align: center;
}
.task_pop_btn button {
  width: 227px;
  height: 43px;
  background-color: #5857ff;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
}
.task_pop_upload >>> .el-upload-dragger {
  width: 193px;
  height: 40px;
  box-sizing: border-box;
  position: relative;
  border: none;
  background: none;
}

.upload_pop {
  position: absolute;
  left: 0;
  top: 0;
  width: 279px;
  height: 184px;
  z-index: 1;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
}
.upload_pop:hover {
  border: 1px dashed #409eff;
  cursor: pointer;
}
.pad15 {
  padding: 0 15px;
}
.zanActive {
  color: #4342ff;
}
.background {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
}
.edit_task_pop {
  position: fixed;
  width: 820px;
  height: 556px;
  background-color: #eff3f5;
  border-radius: 8px;
  top: 50%;
  left: 50%;
  margin-top: -278px;
  margin-left: -410px;
  z-index: 1002;
  opacity: 1 !important;
}
.edit_task_pop_head {
  height: 60px;
  border-bottom: 1px #e0e0e0 solid;
  line-height: 60px;
  padding: 0 30px;
}
.edit_task_pop_head h2 {
  float: left;
  font-size: 18px;
  color: #5857ff;
  height: 59px;
  border-bottom: 2px #5857ff solid;
}
.edit_task_pop_head a {
  float: right;
  font-size: 28px;
  cursor: pointer;
}
.edit_task_pop_content_upload {
  width: 756px;
  height: 233px;
  background-color: #ffffff;
  border-radius: 5px;
  border: solid 1px #eeeeee;
  margin: 22px auto 12px;
  display: flex;
}
.task_pop_thumb {
  width: 210px;
  height: 210px;
  margin-left: 11px;
  margin-top: 11px;
  border: 1px #eee solid;
  background: url(../assets/images/yulan.svg) no-repeat;
  position: relative;
}
.task_pop_thumb img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
.task_pop_upload {
  height: 210px;
  margin-left: 11px;
  flex: 1;
  text-align: center;
}

.task_pop_upload > div {
  font-size: 12px;
  color: #999999;
  line-height: 20px;
  margin-top: 12px;
}
.task_pop_desc {
  width: 756px;
  height: 137px;
  background-color: #ffffff;
  border-radius: 5px;
  border: solid 1px #eeeeee;
  margin: auto;
}
.task_pop_desc textarea {
  width: 728px;
  height: 109px;
  outline: none;
  border: none;
  padding: 14px;
  resize: none;
}
.task_pop_desc textarea::placeholder {
  color: #999999;
  font-size: 14px;
}
.task_pop_btn {
  margin: 23px auto 0;
  text-align: center;
}
.task_pop_btn button {
  width: 227px;
  height: 43px;
  background-color: #5857ff;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
}
.task_pop_upload >>> .el-upload-dragger {
  width: 193px;
  height: 40px;
  box-sizing: border-box;
  position: relative;
  border: none;
  background: none;
}
/* .task_pop_upload >>> .el-upload-dragger div:hover {
  color: #fff;
} */
.upload_pop {
  position: absolute;
  left: 0;
  top: 0;
  width: 279px;
  height: 184px;
  z-index: 1;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
}
.upload_pop:hover {
  border: 1px dashed #409eff;
  cursor: pointer;
}
.video_lists >>> .el-empty {
  width: 100%;
}
.pad10 {
  padding: 0 6px;
}
.page {
  margin-bottom: 20px;
}

.dailyWord_upload_img >>> .el-upload-dragger {
  width: 461px;
  height: 208px;
  background-color: #ffffff;
  border-radius: 10px;
  border: dashed 1px #cccccc;
  position: relative;
}
.dailyWord_upload_img >>> .el-upload-dragger img {
  position: absolute;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.dailyWord_upload_img .el-upload-dragger2 {
  width: 461px;
  height: 208px;
  background-color: #ffffff;
  border-radius: 10px;
  border: dashed 1px #cccccc;
  text-align: center;
  cursor: pointer;
}
.dailyWord_upload_img >>> .upload_icon {
  display: block;
  width: 53px;
  height: 53px;
  margin: 29px auto 23px;
  background: url(../assets/images/upload.png) no-repeat;
}
.dailyWord_upload_img .upload_icon2 {
  display: block;
  width: 53px;
  height: 53px;
  margin: 29px auto 23px;
  background: url(../assets/images/upload.png) no-repeat;
}
.dailyWord_upload_img >>> .el-upload__text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: #333333;
  margin-bottom: 22px;
}

.video_info_play {
  height: 360px;
  min-width: 600px;
}
#player {
  width: 100%;
  height: 360px;
}
.video_info_play_start {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  height: 360px;
  background: #333;
}

.video_info_play_start_thumb {
  height: 100%;
  margin: auto;
}

.video_info_play_start div {
  width: 70px;
  height: 40px;
  background-color: #5957ff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 40px;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 15px;
  left: 17px;
}

.playClick {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  cursor: pointer;
}

.imgDolg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background-color: rgba(140, 134, 134, 0.6);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

#imgDolgClose {
  position: fixed;
  top: 35px;
  cursor: pointer;
  right: 7%;
  font-size: 50px;
  color: white;
}

.imgDolg img {
  max-width: 60%;
  max-height: 60%;
}
</style>